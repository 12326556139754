import React, { Component } from 'react'
import norway from '../images/citycartoon.jpg'
import Card18 from '../components/cards/Card18'
import { getDegreeByUniversity, getPublicPrograms, getSingleDataSC, getSingleDataGO } from '../actions/MainAction';
import { connect } from 'react-redux';
import { withHooks } from '../actions/withHooks';
import LoadMoreBtn from '../components/LoadMoreBtn';
import { Helmet } from 'react-helmet';

export class SingleUniversity extends Component {
  state = {
    uniData: '',
    page: 1,
    degree_id: '',
    inst_id: ''
  }

  componentDidMount() {
    this.props.getSingleDataGO(`public-institution-data?lang_id=1&institution_path=${this.props.params.id}`)
      .then(resp => {
        this.setState({ uniData: resp })
        this.setState({ inst_id: resp?.institutions_id })
        this.props.getDegreeByUniversity(resp?.institutions_id)
          .then(resp => {
            if (resp.length !== 0) {
              this.getProgramByDegree(resp[0]?.education_degree_id)
            }
          })
      })

  }

  loadMore = (page) => {
    this.props.getPublicPrograms(page, this.state.inst_id, this.state.degree_id)
  }

  getProgramByDegree(degree_id) {
    this.setState({ degree_id: degree_id })
    this.props.getPublicPrograms(this.state.page, this.state.inst_id, degree_id)
  }

  render() {
    const { uniData, degree_id } = this.state;
    const { publicPrograms, degreeByUniversity } = this.props;
    // console.log(uniData)
    // console.log(degreeByUniversity);
    return (
      <div className='container'>
        {
          uniData != "" ?
            <Helmet>
              <title>{uniData?.name} | CELT Colleges</title>
              <meta name="description" content={uniData?.seo_description} />
              <meta name="keywords" content={uniData?.seo_keywords} />
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="article" />
              <meta property="og:title" content={`${uniData?.name} | CELT Colleges`} />
              <meta property="og:description" content={uniData?.seo_description} />
              <meta property="og:url" content={`https://celt.az${window.location.pathname}`} />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2022-08-16T14:43:28+00:00" />
              <meta property="og:image" content={uniData?.institutions?.logo_full_url} />

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="3 minutes" />
              <link rel="icon" href="/celt-title.jpeg" />
            </Helmet> : null
        }

        <section>
          <img
            src={uniData?.institutions?.images[0]?.image_full_url ? uniData?.institutions?.images[0]?.image_full_url : norway}
            className='w-full max-h-[60vh] rounded-b-[20px]'
            alt='Country name'
            onError={(e) => { e.target.src = norway }}

          />

          <div className='flex justify-center relative  top-[-120px]  max-[650px]:top-[-60px] max-[400px]:top-[-38px]'>
            <span className='p-[20px_36px] font-[600] text-[35px] text-[#262626] rounded-[15px] bg-[#F3F4F6] max-[650px]:p-[10px_18px] max-[650px]:text-[24px] max-[400px]:text-[14px] max-[400px]:p-[6px_12px]'>
              {uniData?.name}
            </span>
          </div>
        </section>

        <section>
          <div className='flex items-center gap-[20px]'>
            {
              degreeByUniversity?.map((data, i) => {
                return (
                  <button key={i} onClick={this.getProgramByDegree.bind(this, data?.education_degree_id)} className={`${degree_id === data?.education_degree_id ? "bg-[var(--blue)] text-[#fff]" : "border border-[var(--blue)] text-[var(--blue)]"} rounded-[8px] p-[6px_20px] text-[14px] font-[500]`}>
                    {data?.education_type}
                  </button>
                )
              })
            }
          </div>

          <div className='grid grid-cols-3 gap-[20px] mt-[32px] max-[1230px]:grid-cols-2 max-[700px]:grid-cols-1'>
            {
              publicPrograms?.map((data, i) => {
                return (
                  <Card18 key={i}
                    name={data?.name}
                    degree={data?.education_degree}
                    uni={data?.institution}
                    country={data?.country}
                  />
                )
              })
            }
          </div>
          {
            publicPrograms?.length >= 20 ?
              <LoadMoreBtn onLoadData={this.loadMore} defCount={20} count={publicPrograms?.length} /> : null
          }
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  publicPrograms: state.Data.publicPrograms,
  degreeByUniversity: state.Data.degreeByUniversity,
});

const mapDispatchToProps = { getSingleDataSC, getPublicPrograms, getDegreeByUniversity, getSingleDataGO }

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleUniversity))