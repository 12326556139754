import React, { Component } from 'react';
import Card9 from '../components/cards/Card9';
import SectionHeader from '../components/SectionHeader';
import Card13 from '../components/cards/Card13';
import Card20 from '../components/cards/Card20';
import { connect } from 'react-redux';
import { getSingleBranch, getAcceptedStudent, getGraduats, getEnglishExamResults, getstudentsReviewsData } from '../actions/MainAction';
import ExamResultsSection from '../components/ExamResultsSection';
import LoadMoreBtn from '../components/LoadMoreBtn';
import Card27 from '../components/cards/Card27';
import Card4 from '../components/cards/Card4';
import { Helmet } from 'react-helmet';

export class SingleBranch extends Component {
    componentDidMount() {
        const { params, getGraduats, getSingleBranch, getAcceptedStudent, getEnglishExamResults, getstudentsReviewsData } = this.props;
        getGraduats(0.4, params.id);
        getSingleBranch(params.id);
        getAcceptedStudent(0.4, params.id);
        getEnglishExamResults(0.4, params.id);
        getstudentsReviewsData(this.props.lang_id, this.props.dataCount, params.id)
        window.scrollTo(0, 0);
    }

    loadMoreStudents = (page) => {
        const { params, getAcceptedStudent } = this.props;
        getAcceptedStudent(page, params.id);
    };



    loadMoreGraduates = (page) => {
        const { params, getGraduats, } = this.props;
        getGraduats(page, params.id);
    };

    loadMoreExamResults = (page) => {
        const { params, getEnglishExamResults } = this.props;
        getEnglishExamResults(0.40 * page, params.id)
    };

    render() {
        const { companyData, acceptedStudentsData, graduatsData, contentData, englishExamResults, studentsReviewsData } = this.props;
        // console.log(companyData);
        return (
            <div className='w-full'>
                {
                    companyData != "" ?
                        <Helmet>
                            <title>{companyData?.title} | CELT Colleges</title>
                            <meta property="og:locale" content="az_AZ" />
                            <meta property="og:type" content="website" />
                            <meta property="og:title" content={`${companyData?.title} | CELT Colleges`} />
                            <meta property="og:description" content={companyData?.seo_description} />
                            <meta name="description" content={companyData?.seo_description} />
                            <meta name="keywords" content={companyData?.seo_keywords} />
                            <meta property="og:url" content={`https://celt.az${window.location.pathname}`} />
                            <meta property="og:site_name" content="CELT Colleges" />
                            <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
                            <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
                            <meta property="og:image:width" content="900" />
                            <meta property="og:image:height" content="600" />
                            <meta property="og:image:type" content="image/jpeg" />
                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:title" content={`${companyData?.title} | CELT Colleges`} />
                            <meta name="twitter:description" content={companyData?.seo_description} />
                            <meta name="twitter:site" content="@celtcolleges" />
                            <meta name="twitter:label1" content="Est. reading time" />
                            <meta name="twitter:data1" content="7 minutes" />
                        </Helmet> : null
                }


                <section className='container w-full '>
                    <div className='w-full overflow-y-scroll   scrollbar-hidden  '>
                        <Card9
                            id={companyData?.id}
                            phone_number={companyData?.phone_number}
                            location={companyData?.location}
                            facebook={companyData?.facebook}
                            instagram={companyData?.instagram}
                            office_number={companyData?.office_number}
                            title={companyData?.title}
                        />
                    </div>

                    {
                        graduatsData.length > 1 ?
                            <div>
                                {
                                    graduatsData.length > 0 ?
                                        <SectionHeader title={contentData?.high_scholl} /> : null
                                }

                                <div className='container grid grid-cols-4  gap-[16px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
                                    {graduatsData?.map((data, i) => (
                                        <Card13
                                            key={i}
                                            first_name={data?.first_name}
                                            last_name={data?.last_name}
                                            company_name={data?.company_name}
                                            image_full_url={data?.inst_image?.image_full_url}
                                            country_image={data?.country_image}
                                            country_name={data?.country_name}
                                            user_image={data?.user_image}
                                            program_name={data?.program_name}
                                            inst_name={data?.inst_name}
                                            ranking={data?.ranking}
                                            grade={data?.grade}
                                            amount={data?.scholarship?.amount}
                                            currency={data?.scholarship?.currency}
                                            company_id={data?.company_id}
                                        />
                                    ))}
                                </div>
                                {graduatsData?.length >= 8 ? (
                                    <LoadMoreBtn onLoadData={this.loadMoreGraduates} defCount={8} count={graduatsData?.length} />
                                ) : null}
                            </div> : null
                    }

                    <div >
                        <ExamResultsSection mt=" " page={0.4} type={'filter_by_exam'} exam_type_id='5' companyId={this.props.params.id} />
                        <ExamResultsSection mt=" " page={0.4} type={'filter_by_exam'} exam_type_id='1' companyId={this.props.params.id} />
                        <ExamResultsSection mt=" " page={0.4} type={'filter_by_exam'} exam_type_id='2' companyId={this.props.params.id} />
                        <ExamResultsSection mt=" " page={0.4} type={'filter_by_exam'} exam_type_id='3' companyId={this.props.params.id} />
                        <ExamResultsSection mt=" " page={0.4} type={'filter_by_exam'} exam_type_id='4' companyId={this.props.params.id} />
                        <ExamResultsSection mt=" " page={0.4} type={'filter_by_exam'} exam_type_id='7' companyId={this.props.params.id} />
                        <ExamResultsSection mt=" " page={0.4} type={'filter_by_exam'} exam_type_id='6' companyId={this.props.params.id} />

                    </div>

                    <div className=''>
                        {
                            englishExamResults.length > 1 ?
                                <div className='pt-[52px]'>
                                    {
                                        englishExamResults.length > 0 ?
                                            <SectionHeader title={contentData?.sh_our_results} /> : null
                                    }

                                    <div className='container grid grid-cols-4 pt-[52px] gap-[16px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
                                        {
                                            englishExamResults?.map((data, i) => {
                                                return (
                                                    <Card27
                                                        key={i}
                                                        first_name={data?.student?.first_name}
                                                        full_name={data?.student?.full_name}
                                                        last_name={data?.student?.last_name}
                                                        title={data?.exam?.lesson?.title}
                                                        score={data?.score}
                                                        company={data?.student?.company?.name}
                                                        company_id={data?.student?.company_id}
                                                        date={data?.created_at}
                                                        image_full_url={data?.student?.avatar_full_url}
                                                        url={data?.certificate?.certificate_full_url}

                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        englishExamResults?.length >= 8 ?
                                            <LoadMoreBtn onLoadData={this.loadMoreExamResults} defCount={8} count={englishExamResults?.length} /> : null
                                    }
                                </div> : null
                        }
                    </div>



                    <div className='pt-[42px]'>
                        {
                            acceptedStudentsData.length > 0 ?
                                <div>

                                    {
                                        acceptedStudentsData.length > 0 ?
                                            <SectionHeader title={contentData?.nav_3} /> : null
                                    }
                                    <div className='container grid grid-cols-4 pt-[32px] gap-[16px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
                                        {acceptedStudentsData?.map((data, i) => (
                                            <Card20
                                                key={i}
                                                first_name={data?.first_name}
                                                last_name={data?.last_name}
                                                company_name={data?.company_name}
                                                image_full_url={data?.inst_image?.image_full_url}
                                                country_image={data?.country_image}
                                                country_name={data?.country_name}
                                                user_image={data?.user_image}
                                                program_name={data?.program_name}
                                                inst_name={data?.inst_name}
                                                ranking={data?.ranking}
                                                amount={data?.scholarship?.amount}
                                                currency={data?.scholarship?.currency}
                                                company_id={data?.company_id}

                                            />
                                        ))}
                                    </div>
                                    {acceptedStudentsData?.length >= 8 ? (
                                        <LoadMoreBtn onLoadData={this.loadMoreStudents} defCount={8} count={acceptedStudentsData?.length} />
                                    ) : null}
                                </div> : null
                        }
                    </div>
                </section>

                <section className='mt-[52px] bg-[#f9fbfb] pb-[52px]'>
                    <SectionHeader title={contentData?.students_say} />
                    <div className={`grid-cols-3 grid container  gap-[40px] max-[1000px]:grid-cols-2 max-[750px]:grid-cols-1`}>
                        {studentsReviewsData?.map((data, i) => (
                            <Card4
                                key={i}
                                image={data?.image_full_url}
                                name={data?.name}
                                text={data?.text}
                                raiting={data?.raiting}

                            />
                        ))}
                    </div>
                </section>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    companyData: state.Data.companyData,
    acceptedStudentsData: state.Data.acceptedStudentsData,
    graduatsData: state.Data.graduatsData,
    contentData: state.Data.contentData,
    englishExamResults: state.Data.englishExamResults,
    studentsReviewsData: state.Data.studentsReviewsData,


});

const mapDispatchToProps = { getSingleBranch, getAcceptedStudent, getGraduats, getEnglishExamResults, getstudentsReviewsData };

export default connect(mapStateToProps, mapDispatchToProps)(SingleBranch);
