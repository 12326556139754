import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import { connect } from 'react-redux'
import { getAcceptedStudent } from '../actions/MainAction'
import Card20 from '../components/cards/Card20';
import LoadMoreBtn from '../components/LoadMoreBtn';
import { Helmet } from 'react-helmet';

export class StudyGradeatedStudents extends Component {
  componentDidMount() {
    this.props.getAcceptedStudent(1);
  }

  loadMore = (page) => {
    this.props.getAcceptedStudent(page)
  }
  render() {
    const { acceptedStudentsData, contentData } = this.props;
    // console.log(acceptedStudentsData);
    return (
      <div className='container'>
        {
          contentData?.admitted_student ?
            <Helmet>
              <title>
                {contentData?.admitted_student} | CELT Colleges
              </title>
              <meta name="description" content="Qəbul olmuş tələbələrimizlə tanış olun! CELT Colleges-də təhsil alan tələbələrimizin uğurları bizi qürurlandırır." />
              <meta name="keywords" content="qəbul olmuş tələbələr, CELT Colleges, tələbə uğurları, CELT Colleges nəticələri, təhsil uğurları, beynəlxalq qəbul, tələbə profiləri" />
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="article" />
              <meta property="og:title" content="Xaricde Tehsil - Avropa, Amerika və Turkiyede tehsil - Xaricde Dil Kurslari" />
              <meta property="og:description" content="Qəbul olmuş tələbələrimizlə tanış olun! CELT Colleges-də təhsil alan tələbələrimizin uğurları bizi qürurlandırır." />
              <meta property="og:url" content="https://celt.az/xaricde-tehsil/" />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2022-08-16T14:43:28+00:00" />
              <meta property="og:image" content="https://celt.az/static/media/celtLogo.a95db1d2d66058755538.png" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="3 minutes" />
            </Helmet> : null
        }

        <SectionHeader title={contentData?.admitted_student} />

        <div className='container grid grid-cols-4 gap-[8px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
          {
            acceptedStudentsData?.map((data, i) => {
              return (
                <Card20
                  key={i}
                  first_name={data?.first_name}
                  last_name={data?.last_name}
                  company_name={data?.company_name}
                  image_full_url={data?.inst_image?.image_full_url}
                  country_image={data?.country_image}
                  country_name={data?.country_name}
                  user_image={data?.user_image}
                  program_name={data?.program_name}
                  inst_name={data?.inst_name}

                  ranking={data?.ranking}
                  amount={data?.scholarship?.amount}
                  currency={data?.scholarship?.currency}

                  company_id={data?.company_id}

                />
              )
            })
          }
        </div>
        {
          acceptedStudentsData?.length >= 20 ?
            <LoadMoreBtn onLoadData={this.loadMore} defCount={20} count={acceptedStudentsData?.length} /> : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  acceptedStudentsData: state.Data.acceptedStudentsData,
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
})
const mapDispatchToProps = { getAcceptedStudent }
export default connect(mapStateToProps, mapDispatchToProps)(StudyGradeatedStudents)