import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import { connect } from 'react-redux'
import PrimaryCompanies from '../components/PrimaryCompanies'
import KidsCompanies from '../components/KidsCompanies'
import { getprimaryCompanies } from '../actions/MainAction'
import { Helmet } from 'react-helmet'


export class Contacts extends Component {
  state={
    contacsPage:0
  }
  componentDidMount(){
    window.scrollTo(0,0)
    this.props.getprimaryCompanies(this.props.lang_id)
  }
  
 
  onclickPage(index , e){
    this.setState({contacsPage:index})
  }
  
  render() {
    const {contacsPage} = this.state
    const {contentData, primaryCompaniesData } = this.props
    // console.log(primaryCompaniesData)
    return (
        <div className='container'>
          <Helmet>
          <title>{contentData?.contact_title}</title>
          <meta name="description" content="CELT Colleges filialları sizə yaxın hər yerdədir. Sumqayıtda 1, Bakıda isə 12 mərkəzimizlə, keyfiyyətli təhsil üçün sizinləyik." />
          <meta name="keywords" content="CELT Colleges filialları, Sumqayıt təhsil mərkəzi, Bakı təhsil mərkəzləri, keyfiyyətli təhsil, CELT Colleges mərkəzləri, CELT Colleges Sumqayıt, CELT Colleges Bakı, təhsil imkanları" />
          <meta property="og:locale" content="az_AZ" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="CELT Colleges Filialları - Tədris Mərkəzlərimizin Ünvanları" />
          <meta property="og:description" content="CELT Colleges filialları sizə yaxın hər yerdədir. Sumqayıtda 1, Bakıda isə 12 mərkəzimizlə, keyfiyyətli təhsil üçün sizinləyik." />
          <meta property="og:url" content="https://celt.az/contactus/" />
          <meta property="og:site_name" content="CELT Colleges" />
          <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
          <meta property="article:modified_time" content="2023-11-27T14:28:10+00:00" />
          <meta property="og:image" content="https://celt.az/static/media/celtLogo.a95db1d2d66058755538.png" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@celtcolleges" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="3 minutes" />
          </Helmet>


          <div className=' max-[600px]:hidden'>
            <SectionHeader title={contentData?.education_centers}/>
          </div>

          <div className='flex  gap-[12px] items-center mb-[32px] max-[600px]:justify-center max-[600px]:mt-[32px]'>
            <button onClick={()=>this.setState({contacsPage:0})} className={`${contacsPage===0?"text-white bg-[var(--blue)]":" text-[var(--blue)] border-[var(--blue)]"}  py-[4px] w-[120px] rounded-[50px] border-[1px]  text-[17px] font-[500]`}>
              {contentData?.colloges}
            </button>
            
            <button onClick={()=>this.setState({contacsPage:1})} className={`${contacsPage===1?"text-white bg-[var(--blue)]":" text-[var(--blue)] border-[var(--blue)]"} py-[4px] w-[120px] rounded-[50px] border-[1px]  text-[17px] font-[500]`}>
              {contentData?.contact_kids}
            </button>
          </div>
          
        {
          contacsPage===0?
          <PrimaryCompanies />:
          <KidsCompanies />
        }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  contentData: state.Data.contentData,
  primaryCompaniesData: state.Data.primaryCompaniesData,
  lang_id: state.Data.lang_id,
})
const mapDispatchToProps = {getprimaryCompanies}
export default connect(mapStateToProps, mapDispatchToProps) (Contacts)
