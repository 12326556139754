const company_path = [
  {
    "id": 2,
    "path": "/celt-khatai"
  },
  {
    "id": 4,
    "path": "/celt-colleges-28-may/"
  },
  {
    "id": 5,
    "path": "/celt-colleges-iceri-seher/"
  },
  {
    "id": 6,
    "path": "/celt-colleges-sahil-metrosu/"
  },
  {
    "id": 7,
    "path": "/celt-colleges-bakixanovda/"
  },
  {
    "id": 8,
    "path": "/celt-nizami/"
  },
  {
    "id": 9,
    "path": "/celt-colleges-ecemi/"
  },
  {
    "id": 10,
    "path": "/celt-colleges-narimanov/"
  },
  {
    "id": 11,
    "path": "/celt-colleges-ingilab/"
  },
  {
    "id": 12,
    "path": "/celt-colleges-elmler/"
  },
  {
    "id": 13,
    "path": "/celt-hazi-aslanov/"
  },
  {
    "id": 14,
    "path": "/celt-colleges-sumqayit/"
  },
  {
    "id": 17,
    "path": "/celt-kids-narimanov-2"
  },
  {
    "id": 18,
    "path": "/celt-kids-elmler-2/"
  },
  {
    "id": 22,
    "path": "/celt-kids-sahil/"
  },
  {
    "id": 23,
    "path": "/celt-high-school-3/"
  },
  {
    "id": 29,
    "path": "/celt-colleges-knightsbridge/"
  },
  {
    "id": 30,
    "path": "/celt-colleges-ganjlik"
  },
  {
    "id": 33,
    "path": "/celt-ag-sheher"
  },
  {
    "id": 35,
    "path": "/celt-high-school-bilgah"
  }
]

export const getCompanyPath = (id) => {
  const path = company_path.find(x => x.id == id);
  if (path) {
    return path["path"];
  } else {
    return `/contact/${id}`;
  }
}

export const getPathUrl = (name) => {
  return name?.replaceAll(" ", "-").toLowerCase()
}