import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import SectionDescription from '../components/SectionDescription'
import Card22 from '../components/cards/Card22'
import train from '../images/train.png'
import LocationSvg from '../components/svg/LocationSvg'
import DowloadSvg from '../components/svg/DowloadSvg'
import { connect } from 'react-redux'
import Phone2Svg from '../components/svg/Phone2Svg'
import FacebookSvg from '../components/svg/FacebookSvg'
import ImageGallery from '../components/ImageGallery'
import CardSvg from '../components/svg/CardSvg'
import BuildSvg3 from '../components/svg/BuildSvg3'
import { Helmet } from 'react-helmet'


export class SummerCamp extends Component {
  render() {
    const {contentData} = this.props;
    return (
      <div className='container'>
        {
          contentData?.summer_camp?
          <Helmet>
          <title>{contentData?.summer_camp} | CELT Colleges</title>
          <meta property="og:locale" content="az_AZ" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={`${contentData?.summer_camp} | CELT Colleges`} />
          <meta property="og:description" content="CELT Colleges hər il şagirdlər üçün ingilis dili yay düşərgəsi keçirir. 2009-cu ildən davamlı olaraq təşkil edilən bu düşərgələrdə, şagirdlər həm dərs stresini atır, həm də ingilis dilini praktika edir." />
          <meta property="description" content="CELT Colleges hər il şagirdlər üçün ingilis dili yay düşərgəsi keçirir. 2009-cu ildən davamlı olaraq təşkil edilən bu düşərgələrdə, şagirdlər həm dərs stresini atır, həm də ingilis dilini praktika edir." />
          <meta property="keywords" content="CELT Colleges, ingilis dili yay düşərgəsi, ingilis dili tədrisi, yay düşərgələri, şagirdlər üçün ingilis dili, yay təlim proqramları, CELT Colleges yay proqramları" />
          <meta property="og:url" content="https://celt.az/summer-school/" />
          <meta property="og:site_name" content="CELT Colleges" />
          <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
          <meta property="article:modified_time" content="2024-04-03T07:26:25+00:00" />
          <meta property="og:image" content="https://celt.az/static/media/celtLogo.a95db1d2d66058755538.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@celtcolleges" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="4 minutes" />
        </Helmet>:null
        }
        
        <section className='flex flex-col gap-[32px]'>
          <div className='embed_header_video w-full mt-[10px]'>
            
            {/* <iframe 
              src="https://www.youtube.com/embed/Jq1h812JMrM?si=p9TAxTg2PVodSIlZ" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" 
              allowfullscreen>
            </iframe> */}

            <iframe 
            src="https://www.youtube.com/embed/Jq1h812JMrM?si=JT8U2kYPCEx1PXwF" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen>
              
            </iframe>


              
          </div>

          <div className='flex flex-col gap-[32px]'>
            <SectionDescription text='CELT COLLEGES HƏR IL ŞAGIRDLƏR ÜÇÜN INGILIS DILLI YAY DÜŞƏRGƏSI KEÇIRIR. 2009-CI ILDƏN DAVAMLI KEÇIRILƏN YAY DÜŞƏRGƏSINDƏ ŞAGIRDLƏR HƏM DƏRSLƏRIN STRESINI ATIRLAR HƏM DƏ INGILIS DILINI PRAKTIKA EDIRLƏR. DÜŞƏRGƏ AZƏRBAYCANIN OLIMPIYA KOMPLEKSLƏRINDƏ KEÇIRILIR.' />

            <div className='flex flex-col h-auto border-[1px] rounded-[8px] p-[20px]'>
              <h2 className='font-[700] text-center text-[24px]'>
                {contentData?.summerComp_h2}
              </h2>
              <span className='font-[500] text-[20px] text-[#54595F]'>{contentData?.summerComp_inculuded_program}</span>

              <div className='p-[0px_40px] pt-[20px] flex flex-col gap-[20px]'>
                <ul className=' list-cricle h-auto flex flex-col gap-[5px]'>
                  <li className='font-[500]  text-[20px] text-[#54595F]'>{contentData?.summerComp_transportation}</li>
                  <li className='font-[500]  text-[20px] text-[#54595F]'>{contentData?.summerComp_text2}</li>
                  <li className='font-[500]  text-[20px] text-[#54595F]'>{contentData?.summerComp_text3}</li>
                  <li className='font-[500]  text-[20px] text-[#54595F]'>{contentData?.summerComp_text4}</li>
                  <li className='font-[500]  text-[20px] text-[#54595F]'>{contentData?.summerComp_text5}</li>
                  <li className='font-[500]  text-[20px] text-[#54595F]'>{contentData?.summerComp_text6}</li>
                  <li className='font-[500]  text-[20px] text-[#54595F]'>{contentData?.summerComp_text7}</li>
                  <li className='font-[500]  text-[20px] text-[#54595F]'>{contentData?.summerComp_text8}</li>
                  <li className='font-[500]  text-[20px] text-[#54595F]'>{contentData?.summerComp_text9}</li>
                </ul>
              </div>  


              <div className='flex items-center justify-center gap-[32px] mt-[20px]'>
                <div className='flex flex-col gap-[5px] items-center'>
                  <span className='font-[500] text-[20px] text-[#54595F]'>{contentData?.summerComp_text10}</span>
                  <span className='font-[500] text-[20px] text-[#54595F]'>{contentData?.summerComp_text11}</span>
                </div>
              </div>

              <div className='flex justify-center items-center mt-[20px]'>
                <a href='https://dcek.celt.az/storage/uploads/pdf/information.pdf'  target='_blank' className='flex items-center gap-[] bg-[var(--white)] p-[20px_30px] text-[var(--blue)] font-[500] text-[20px] rounded-[8px] shadow-[0px_0px_16px_0px_#00000030]'>
                  {contentData?.see_more}
                    <DowloadSvg />
                </a>
              </div>
                
              <div className=' flex flex-col gap-[13px] justify-center'>                  
                <div className='flex items-center gap-[32px] justify-end mt-[12px] max-[500px]:justify-center'>
                  <a href='tel:+994552107557'  target='_blank' className='flex items-center gap-[12px] bg-[var(--white)] p-[20px] rounded-[8px] shadow-[0px_0px_16px_0px_#00000030]'>
                    <Phone2Svg width='32' height='32' fill='var(--blue)' />
                  </a>

                  <a href='https://www.facebook.com/celtortamektebyaydusergesi?mibextid=LQQJ4d' target='_blank' className='flex items-center gap-[12px] bg-[var(--white)] p-[20px] rounded-[8px] shadow-[0px_0px_16px_0px_#00000030]'>
                    <FacebookSvg width='32' height='32' fill='var(--blue)' />
                  </a>
                </div>
              </div>

            </div>
          </div>

        </section>
        

        <section>
            <SectionHeader title={contentData?.summer_camp}/>
            {/* <SectionDescription text='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s' /> */}



            <div className=' bg-[#f79027] pt-[30px] pb-[60px] mt-[52px] flex flex-col gap-[40px]'>
              <div className='flex items-center justify-center max-[520px]:flex-col max-[520px]:gap-[14px]'>
                <div>
                  <LocationSvg width='45' height='45' fill='#fff' />
                </div>
                <span className='text-[40px] text-[#fff] font-[700] text-center max-[850px]:text-[32px] max-[620px]:text-[26px]'>{contentData?.summer_camp_gusar}</span>
              </div>
              

              <div className='grid grid-cols-4 gap-[20px] max-[850px]:grid-cols-2 max-[850px]:gap-[0] max-[850px]:p-[20px]'>

                <Card22 
                    border_bottom='max-[850px]:border-b-[1px]'
                    border='border-r-[1px]'
                    size='2,1'
                    text={contentData?.summerComp_text12}
                    svg={<CardSvg/>}
                    width='0px'
                    height='0px'
                />

                 <Card22 
                  border='border-r-[1px] '
                  border_bottom='max-[850px]:border-b-[1px] max-[850px]:border-r-[0px]'
                  img={train}
                  size='2,4'
                  text={contentData?.summerComp_text13}
                  width='70px'
                  height='70px'
                />
                 <Card22 
                svg={<BuildSvg3 />}
                  border_bottom='mt-[-1px]'
                  border='border-r-[1px]'
                  size='1,50'
                  text={contentData?.summerComp_text14}
                  width='70px'
                  height='70px'
                />


                <Card22 
                  border_bottom='max-[850px]:border-r-[0px]'
                  border=''
                  svg={<CardSvg/>}
                  size='6'
                  text={contentData?.summerComp_text15}
                  width='70px'
                  height='70px'
                />

                
               
               

              </div>
            </div>
            
        </section>

        <section>
            <SectionHeader title={contentData?.sh_gallery} />
              <ImageGallery />
            {/* <Gallery /> */}
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,

})
export default connect(mapStateToProps)(SummerCamp)