import React, { Component } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import { getContent } from './actions/MainAction'
import MainPage from './pages/MainPage'
import Footer from './components/footer/Footer'
import GraduatedStudents from './pages/GraduatedStudents'
import LanguagePreparation from './pages/LanguagePreparation'
import StudyAbroad from './pages/StudyAbroad'
import KidsEnglish from './pages/KidsEnglish'
import Exams from './pages/Exams'
import Contacts from './pages/Contacts'
import SingleBranch from './pages/SingleBranch'
import SingleExams from './pages/SingleExams'
import NavBar from './components/header/NavBar'
import Blog from './pages/Blog'
import SingleBlogs from './pages/SingleBlogs'
import SingleSchools from './pages/SingleSchools'
import StudyGradeatedStudents from './pages/StudyGradeatedStudents'
import SummerCamp from './pages/SummerCamp'
import GalleryModal from './components/GalleryModal'
import SingleCountries from './pages/SingleCountries'
import SingleUniversity from './pages/SingleUniversity'
import ScrollToTop from './components/ScrollToTop';
import MainSingleExams from './pages/MainSingleExams'
import AllSchools from './pages/AllSchools'
import SuccessPopup from './components/SuccessPopup'
import Loadin from './components/Loadin'
import StudyCenters from './pages/StudyCenters'
import ErorPage from './pages/ErorPage'

export class App extends Component {
  componentDidMount() {
    this.props.getContent(this.props.lang_id);
  }

  render() {
    const { contentData, galleryModalData, popUpVisible, loader } = this.props;
    return (
      <div>
        <BrowserRouter>
          <NavBar />
          {/* <Sidebar /> */}
          <ScrollToTop>
            <Routes>
              <Route path='/' element={<MainPage />} />
              <Route path='/xaricde-oxuyanlar/' element={<GraduatedStudents />} />
              <Route path='/ingilis-dili-kursu/' element={<LanguagePreparation />} />
              <Route path='/xaricde-tehsil/' element={<StudyAbroad />} />
              <Route path='/qebul-olmus-telebeler' element={<StudyGradeatedStudents />} />
              <Route path='/xaricde-tehsil/:id' element={<SingleCountries />} />
              <Route path='/university/:id' element={<SingleUniversity />} />
              <Route path='/kids' element={<KidsEnglish />} />
              <Route path='/main-page/:id' element={<MainSingleExams />} />
              <Route path='/xaricde-tehsil-merkezlerimiz' element={<StudyCenters />} />

              <Route path='/summer-school/' element={<SummerCamp />} />

              <Route path='/muhasibatliq' element={<MainSingleExams params={{ id: 23 }} />} />
              <Route path='/beynalxalq-muellim-sertifikati' element={<MainSingleExams params={{ id: 32 }} />} />
              {/* <Route path='/online-ingilis-dili-kursu' element={<MainSingleExams params={{ id: 20 }} />} /> */}


              <Route path='/ielts-imtahani' element={<SingleExams params={{ id: 1 }} />} />
              <Route path='/toefl-imtahani' element={<SingleExams params={{ id: 2 }} />} />
              <Route path='/sat-qebul-imtahani' element={<SingleExams params={{ id: 3 }} />} />
              <Route path='/gre-imtahani' element={<SingleExams params={{ id: 4 }} />} />
              <Route path='/duolingo-imtahani' element={<SingleExams params={{ id: 6 }} />} />
              <Route path='/gmat-imtahani' element={<SingleExams params={{ id: 9 }} />} />

              <Route path='/ingilis-dili-kurslari' element={<SingleExams params={{ id: 11 }} />} />
              <Route path='/online-ingilis-dili' element={<SingleExams params={{ id: 13 }} />} />
              <Route path='/crash-ingilis-dili' element={<SingleExams params={{ id: 14 }} />} />

              <Route path='/exams/:id' element={<SingleExams />} />
              <Route path='/imtahanlar/' element={<Exams />} />

              <Route path='/schools' element={<AllSchools />} />
              <Route path='/private-schools' element={<SingleSchools schoolType={'1'} />} />
              <Route path='/primary-schools' element={<SingleSchools schoolType={'2'} />} />
              <Route path='/secondary-schools' element={<SingleSchools schoolType={'3'} />} />


              <Route path='/contactus/' element={<Contacts />} />
              <Route path='/celt-khatai/' element={<SingleBranch params={{ id: 2 }} />} />
              <Route path='/celt-colleges-28-may/' element={<SingleBranch params={{ id: 4 }} />} />
              <Route path='/celt-colleges-iceri-seher/' element={<SingleBranch params={{ id: 5 }} />} />
              <Route path='/celt-colleges-sahil-metrosu/' element={<SingleBranch params={{ id: 6 }} />} />
              <Route path='/celt-colleges-bakixanovda/' element={<SingleBranch params={{ id: 7 }} />} />
              <Route path='/celt-nizami/' element={<SingleBranch params={{ id: 8 }} />} />
              <Route path='/celt-colleges-ecemi/' element={<SingleBranch params={{ id: 9 }} />} />
              <Route path='/celt-colleges-narimanov/' element={<SingleBranch params={{ id: 10 }} />} />
              <Route path='/celt-colleges-ingilab/' element={<SingleBranch params={{ id: 11 }} />} />
              <Route path='/celt-colleges-elmler/' element={<SingleBranch params={{ id: 12 }} />} />
              <Route path='/celt-hazi-aslanov/' element={<SingleBranch params={{ id: 13 }} />} />
              <Route path='/celt-colleges-sumqayit/' element={<SingleBranch params={{ id: 14 }} />} />
              <Route path='/celt-high-school-3/' element={<SingleBranch params={{ id: 23 }} />} />
              <Route path='/celt-colleges-knightsbridge/' element={<SingleBranch params={{ id: 29 }} />} />
              <Route path='/celt-colleges-ganjlik' element={<SingleBranch params={{ id: 30 }} />} />
              <Route path='/celt-ag-sheher' element={<SingleBranch params={{ id: 33 }} />} />
              <Route path='/celt-kids-narimanov-2/' element={<SingleBranch params={{ id: 17 }} />} />
              <Route path='/celt-kids-elmler-2/' element={<SingleBranch params={{ id: 18 }} />} />
              <Route path='/celt-kids-sahil/' element={<SingleBranch params={{ id: 22 }} />} />


              <Route path='/blogs' element={<Blog />} />
              <Route path='/blog/:path' element={<SingleBlogs />} />
              <Route path='*' element={<ErorPage />} />


              {/* <Route path='/premium' element={<PremiumGraduets />} /> */}
              {/* <Route path='/single-graduent/:id' element={<SinglePremiumGraduets />} /> */}
            </Routes>
          </ScrollToTop>
          <Footer />
          {/* <SupportSection /> */}
          {galleryModalData?.position ? <GalleryModal /> : null}
          {popUpVisible ? <SuccessPopup /> : null}
          {loader ? <Loadin /> : null}
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
  galleryModalData: state.Data.galleryModalData,
  popUpVisible: state.Data.popUpVisible,
  loader: state.Data.loader,
});

const mapDispatchToProps = { getContent };

export default connect(mapStateToProps, mapDispatchToProps)(App);